import { ref } from 'vue'
import axios from 'axios'
import qs from 'qs'
import store from "../config/store";

const baseUrl = '/', baseApi = 'https://api.1itmedia.co.id';
const secretApiStatic = '2dc2968735c4fa0b047834a73ce5dff7a46a73871a37265a35e1e3eff8df72c3';
const secretApi = (store.getters.stateUser) ? store.getters.stateUser.secret : secretApiStatic;
let isHeader = {'X-Token-Secret': secretApi};
let isHeaderUrlencoded = {'X-Token-Secret': secretApi, 'Content-Type': 'application/x-www-form-urlencoded'};
let isHeaderStatic = {'X-Token-Secret': secretApiStatic};
let isHeaderUrlencodedStatic = {'X-Token-Secret': secretApiStatic, 'Content-Type': 'application/x-www-form-urlencoded'};

const shippingApi = {env:'production', username:'RABBANIASYSA', key:'e072b9ac674b405ab58a5982fb79232b'};
let headerShipping = {'X-Token-Secret': secretApiStatic, 'X-Jne-Env':shippingApi.env, 'X-Jne-Username':shippingApi.username, 'X-Jne-Api-Key': shippingApi.key};

export function getBase(isUrl = '') {
    return baseUrl+isUrl;
}

export function handleError(isRespone) {
  var reason = 'Failed process data', existError = false;
  let resData = (isRespone.response) ? isRespone.response.data : ( (isRespone.data) ? isRespone.data : false );

  if(resData && resData.errors && resData.errors.length){
      var detailError = resData.errors[0];
      reason = (detailError.detail) ? detailError.detail : reason;
      existError = true;
  }
  if(!existError && resData.message){
      reason = `${resData.message}`;
  }
  
  return {success:false, msg:reason};
}

export function getData(url, attribute={}) {
  let setHeader = (attribute && attribute.static) ? isHeaderStatic : isHeader;
  return axios.get(baseApi+'/'+url, { headers: setHeader });
}
export function postData(url, data, attribute={}) {
  let defHeader = (attribute && attribute.static) ? isHeaderStatic : isHeader;
  let ulcHeader = (attribute && attribute.static) ? isHeaderUrlencodedStatic : isHeaderUrlencoded;
  let setHeader = (attribute && attribute.urlencoded) ? ulcHeader : defHeader;
  return axios.post(baseApi+'/'+url,  (attribute && attribute.urlencoded) ? qs.stringify(data) : data, { headers: setHeader });
}
export function delData(url, attribute={}) {
  let setHeader = (attribute && attribute.static) ? isHeaderStatic : isHeader;
  return axios.post(baseApi+'/'+url, '', { headers: setHeader });
}
export function deleteData(url, attribute={}, data = {}) {
  let readyData = (Object.keys(data).length === 0) ? false : true;
  let defHeader = (attribute && attribute.static) ? isHeaderStatic : isHeader;
  let ulcHeader = (attribute && attribute.static) ? isHeaderUrlencodedStatic : isHeaderUrlencoded;
  let setHeader = (attribute && attribute.urlencoded) ? ulcHeader : defHeader;
  if(readyData){
    return axios.delete(baseApi+'/'+url, { data: (attribute && attribute.urlencoded) ? qs.stringify(data) : data }, { headers: setHeader });
  }else{
    return axios.delete(baseApi+'/'+url, { headers: setHeader });
  }
}

export function getDataCityShipping(url) {
  return axios.get(baseApi+'/'+url, { headers: headerShipping });
}