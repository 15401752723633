const state = { userJamaah: null, secretUserJamaah: null, total_qty: 0};
const getters = {
  isAuthenticated: (state) => !!state.userJamaah,
  stateUser: (state) => state.userJamaah,
  stateTotalQty: (state) => state.total_qty,
};

const actions = {
  async stateTotalQty({commit}, data) { await commit("totalQty", data); },
  async logIn({commit}, userJamaah) { await commit("setUser", userJamaah); },
  async logOut({ commit }) { commit("logout"); },
};

const mutations = {
  totalQty(state, data) { state.total_qty = data; },
  setUser(state, userJamaah) { state.userJamaah = userJamaah; },
  logout(state) { state.userJamaah = null; },
};

export default { state, getters, actions, mutations};
