import moment from 'moment'
import $ from "jquery";
import { isNumber } from './validation'

export function external() { console.log('ok'); };
export function destroyTable(target){ var table = $('#'+target).DataTable(); table.destroy(); };
export function simpleAlert(text, outsideClose=true){ Swal.fire({title:text, allowOutsideClick: outsideClose, showCloseButton: true }); };
export function showAlert(event={}, outsideClose=false){
	Swal.fire({ icon: event.type, title: event.title, text: event.text, footer: event.footer, showCloseButton: true, allowOutsideClick: outsideClose });
};
export function confirmAlert(event={}, outsideClose=false){
	return Swal.fire({
	  title: event.title, text: event.text, icon: event.type,
	  showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33',
	  confirmButtonText: (event.confirmLabel) ? event.confirmLabel : 'Yes',
	  allowOutsideClick:outsideClose,footer: event.footer
	});
};
export function resAlert(event={}, outsideClose=true){
	Swal.fire({
		position: 'center', icon: event.type, title: event.title, text: event.text, showConfirmButton: false, timer: (event.timer) ? event.timer : 3000,
		showCloseButton: false, allowOutsideClick: outsideClose, footer:event.footer
	});
};
export function loadingAlert(event={}){
	Swal.fire({
		allowOutsideClick:false, title: event.title, html: event.text, footer:event.footer, timer: (event.timer) ? event.timer : false, timerProgressBar: true,
		onBeforeOpen: () => { Swal.showLoading(); },
	})
};
export function textAlert(event={}){
	let isText = (event.text) ? event.text : '';
	if(event.type=='new'){
		return `Data ${event.data} baru berhasil dibuat. ${isText}`;
	}else if(event.type=='update'){
		return `Data ${event.data} berhasil diperbarui. ${isText}`;
	}else if(event.type=='delete'){
		return `Data ${event.data} berhasil dihapus. ${isText}`;
	}else if(event.type=='err_respone'){
		return `Gagal memproses data ${event.data}. ${isText}`;
	}else if(event.type=='err_process'){
		return `Gagal memproses data ${event.data}. Periksa kembali data yang dikirim atau coba lagi. ${isText}`;
	}else if(event.type=='confirm_delete'){
		return `Yakin ingin hapus data ${event.data} tersebut? ${isText}`;
	}else if(event.type=='success'){
		return `Permintaan ${event.data} berhasil diproses. ${isText}`;
	}
};
export function numberFormat(value, params={}) {
	var isformat = {};
	isformat.currency = { style: 'currency', currency: 'IDR', minimumFractionDigits: 0}
	let options = (params && params.style) ? isformat[params.style] : false;
    var formatter = new Intl.NumberFormat('id-ID', options);
    return formatter.format(value);
};
export function randomString(type='') {
	var characters = 'ABCDEFGHIJKL0123456789MNOPQRSTUVWXYZ';
	if(type=='text'){ characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; }else if(type=='number'){ characters = '0123456789'; }
    var result = [], charactersLength = characters.length;
    for ( var i = 0; i < 5; i++ ) { result.push(characters.charAt(Math.floor(Math.random() * charactersLength))); }
    return result.join('');
};
export function dateFormat(value, format=false) {
	let isFormat = (format) ? format : 'DD/MM/YYYY hh:mm';
	return moment(String(value)).format(isFormat)
};
export function slcImage(target) {
	$('#'+target).click();
};
export function clrPreviewImage(target) {
	var isTarget = $("#"+target);
	var beforeDisplay = $("#preview-"+target).attr('alt');
	let isDisplay = (beforeDisplay && beforeDisplay.length) ? '<img src="'+beforeDisplay+'"/>' : '<em class="icon ni ni-camera"></em>';
	isTarget.val(''); $('#preview-'+target).html(isDisplay);
	$('#reset-'+target).addClass('hide_element');
}
export function previewImage(target) {
	var thisId = document.getElementById(target);
	if (thisId && thisId.files && thisId.files[0]) {
		var countFile = thisId.files.length, pathFile = thisId.value;
	    if(countFile==0 || pathFile==''){ clrPreviewImage(target); return false; }
	    var sizeFile = $('#'+target)[0].files[0].size, nameFile = $('#'+target)[0].files[0].name;
	    var ext = nameFile.split('.').pop(), ekstensiOk = /(\.jpg|\.jpeg|\.png|\.jfif|\.webp)$/i;
	    var sizeKb = sizeFile / 1000, sizeMb = sizeKb / 1000;
		if(!ekstensiOk.exec(pathFile) || sizeMb>5){
			showAlert({type:'warning', title:'Image Not Support', text:'Silakan upload gambar yang memiliki ekstensi (.jpeg/.jpg/.png/.jfif/.webp), Maksimal ukuran 5mb!'});
			clrPreviewImage(target); return false;
		}else{
			var oFReader = new FileReader();
			oFReader.readAsDataURL(thisId.files[0]);
			oFReader.onload = function (oFREvent) {
			  var images = oFREvent.target.result;
			  var isDisplay = '<img src="'+images+'"/>';
			  $('#preview-'+target).html(isDisplay); $('#reset-'+target).removeClass('hide_element');
			};
		}
	}else{
		clrPreviewImage(target);return false;
	}
};
export function popupImage(title, image) { $('#title-popup-image').html(title); $('#content-popup-image').html('<img src="'+image+'"/>'); };
export function popupForm(title, content) {
	$('#title-popup-form').html(title);
	var isContent = '';
	for(var ctn=0; ctn<content.length; ctn++){
		let lblCtn = (content[ctn].title) ? `<label class="form-label mb-0">${content[ctn].title} ${(content[ctn].required) ? '<code>*</code></label>' : ''}` : '';
		let startCtn = (content[ctn].type=='textarea') ? 'textarea' : 'input';
		let endCtn = (content[ctn].type=='textarea') ? '</textarea>' : '';
		var typeInputCtn = `<${startCtn} type="${content[ctn].type}" class="form-control" id="${content[ctn].id}" name="${content[ctn].name}" placeholder="${content[ctn].placeholder}" ${(content[ctn].required) ? 'required' : ''}>${endCtn}`;
		var inputCtn = `<div class="form-group mb-1">${lblCtn}${typeInputCtn}</div>`;
		isContent = isContent+inputCtn;
	}
	$('#content-popup-form').html(isContent);
};
export function closePopup(id) { $('#title-'+id).html(''); $('#content-'+id).html(''); };
export function closeSlcDataList(type){
	var ctnData = $('#ctn-slc-data-'+type), forCountData = $('#cnt-slc-data-'+type), fortextData = $('#slc-data-'+type);
	ctnData.removeClass('active'); forCountData.html(0); fortextData.html('');
	$('#slc-data-'+type+'-all').prop('checked', false); $('.slc-data-'+type).prop('checked', false);
}
export function slcDataList(type, data){
	var ctnData = $('#ctn-slc-data-'+type), forCountData = $('#cnt-slc-data-'+type), fortextData = $('#slc-data-'+type);
	if(data=='close'){
		closeSlcDataList(type); return false;
	}else{
		var isElm = document.getElementById('slc-data-'+type+'-'+data);
		if(!isElm){ return false; }
		if(data=='all'){
			$('.slc-data-'+type).prop('checked', isElm.checked);
			if(!isElm.checked){ closeSlcDataList(type); return false; }
		}
		var chks = document.getElementsByName('data-'+type);
		var results = {data:[], text:[]};
	    for(var i = 0; i < chks.length; i++){
	    	var statSlc = chks[i].checked ? true : false;
	    	if(statSlc){
	    		var isIdData = chks[i].value;
		    	var cekLbl = document.getElementById('label-'+type+'-'+isIdData);
		    	let isLblData = (cekLbl && cekLbl.innerHTML && cekLbl.innerHTML.length) ?  cekLbl.innerHTML : isIdData;
		        results.data.push(isIdData); results.text.push(isLblData);
	    	}
	    }
	    let textSelected = (results.text && results.text.length) ? String(results.text.join(', ')) : 'No data selected.';
	    fortextData.html(textSelected);
	    let statData = (results.data && results.data.length) ? true : false;
	    let countData = (statData) ? results.data.length : 0;
	    forCountData.html(countData);
	    (statData) ? ctnData.addClass('active') : ctnData.removeClass('active');
	    return results;
	}
}
export function syncCountData(attribute={}) {
	const isNewCount = parseInt($('#count-'+attribute.type).attr('alt'))-parseInt(attribute.count);
	$(attribute.elm+'#'+attribute.type+'-'+attribute.id).parent(attribute.parent+'.'+attribute.type+'-list').remove();
	(isNewCount>0) ? $('#count-'+attribute.type).html(numberFormat(isNewCount)).attr('alt', isNewCount) : '';
	return isNewCount;
};
export function woohSelect(elm={}) {
 	if(elm.type=='select' || elm.type=='default'){
		$('#wooh-select-'+elm.data).html(elm.label);
		$('.wooh-select-item-'+elm.data).removeClass('bg-lighter').removeClass('active');
		$('#wooh-select-item-'+elm.data+'-'+elm.id).addClass('bg-lighter').addClass('active');
	}
	if(elm.type!='default'){
		$('#wooh-select-content-'+elm.data).toggleClass('hide_element');
		$('#wooh-select-icon-'+elm.data+' .icon-show').toggleClass('hide_element');
		$('#wooh-select-icon-'+elm.data+' .icon-hide').toggleClass('hide_element');
	}
};
export function showHidePassword(target){
  var value = $('#'+target+' input').val();
  if(value.length==0){$('#'+target+' input').focus();return false;}
  var type = $('#'+target+' input').attr('type');
  if(type=='password'){
    $('#'+target+' input').attr('type', 'text');$('#'+target+' .show_hide_password').html('<em class="passcode-icon icon-show icon ni ni-eye"></em>');
  }else{
    $('#'+target+' input').attr('type', 'password');$('#'+target+' .show_hide_password').html('<em class="passcode-icon icon-show icon ni ni-eye-off"></em>');
  }
};
export function scrolToTop(target){$('html, body').animate({ scrollTop: 0 }, 'fast');};
export function scrolToElement(target){$('html,body').animate({ scrollTop: $("#"+target).offset().top},'slow');};
export function formatCash(n, forFalse){
	if(!n || n==0) return forFalse;
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};
export function handleNumberSpinner(isTarget, attribute = {}){
    var isVal = $('#'+isTarget).val();
    if(!isVal || !isNumber(isVal)){
        isVal = attribute.min;
    }else{
        var thisVal = parseInt(isVal);
        var newVal = (attribute.type=='change') ? thisVal : ( (attribute.type=='minus') ? thisVal-1 : thisVal+1 );
        isVal = newVal;
        if(newVal<=attribute.min){
            isVal = attribute.min;
        }else if(newVal>=attribute.max){
            isVal = attribute.max;
        }
    }
    $('#'+isTarget).val(isVal);
};