import { createRouter, createWebHistory, useRouter } from 'vue-router'
import store from "../config/store";
import { getBase } from "../config/api";

const baseRoute = getBase();
//define a routes
const routes = [
    {
        path: baseRoute, alias: baseRoute+'home', name: 'home',
        meta: { readyPage:true, withHeader:true, withFooter:true },
        component: () => import('@/views/Home/Index.vue')
    },
    {
        path: baseRoute+'login', name: 'login',
        meta: { readyPage:true, withHeader:true, withFooter:false, onlyNotAUth:true },
        component: () => import('@/views/Home/Login.vue')
    },
    {
        path: baseRoute+'aktivasi', name: 'activation',
        meta: { readyPage:true, withHeader:true, withFooter:false, onlyNotAUth:true },
        component: () => import('@/views/Home/Activation.vue')
    },
    {
        path: baseRoute+'produk', alias: baseRoute+'product', name: 'product',
        meta: { readyPage:true, withHeader:true, withFooter:true, requiresAuth:true },
        component: () => import('@/views/Product/Index.vue')
    },
    {
        path: baseRoute+'keranjang', alias: baseRoute+'cart', name: 'cart',
        meta: { readyPage:true, withHeader:true, withFooter:true, requiresAuth:true },
        component: () => import('@/views/Product/Cart.vue')
    },
    {
        path: baseRoute+'pembelian', alias: baseRoute+'transaction', name: 'transaction',
        meta: { readyPage:true, withHeader:true, withFooter:true, requiresAuth:true },
        component: () => import('@/views/Product/Transaction.vue')
    },
    {
        path: baseRoute+'profil', alias: baseRoute+'profile', name: 'profile',
        meta: { readyPage:true, withHeader:true, withFooter:true, requiresAuth:true },
        component: () => import('@/views/User/Index.vue')
    },
    {
        path: baseRoute+'addAddress', alias: baseRoute+'addAddress', name: 'addAddress',
        meta: { readyPage:true, withHeader:true, withFooter:true, requiresAuth:true },
        component: () => import('@/views/User/AddAddress.vue')
    },
    {
        path: baseRoute+'pagenotfound', name: 'pagenotfound',
        meta: { readyPage:true, withHeader:false, withFooter:false },
        component: () => import('@/views/Page/NotFound.vue')
    },

]

//create router
const router = createRouter({ history: createWebHistory(), useroute: useRouter(), routes })

router.beforeEach((to, from, next) => {
    if(to.matched.some((record) => record.meta.readyPage)) {
        if(to.matched.some((record) => record.meta.requiresAuth)) {
            if (store.getters.isAuthenticated) { next(); return; }
            next(baseRoute+'login');
        }else if(to.matched.some((record) => record.meta.onlyNotAUth)) {
            if (store.getters.isAuthenticated) {  next(baseRoute); return; }
            next();
        }else{ next(); }
    }else{ next(baseRoute+'pagenotfound'); }
});

export default router