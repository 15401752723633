<template>
    <section class="section section-footer bg-app p-3 mt-5">
        <div class="row m-0 align-items-center">
            <div class="col-12 col-lg-7 text-center">
              <img class="w-50" src="../assets/images/app/ico_jamaah_logotype.png" alt="Rabbani jamaah">
            </div>
            <div class="col-12 col-lg-5">
              <ul class="link-footer">
                  <li>
                      <a :href="'https://rabbani.id'" target="_blank" class="btn text-white ls-05 pb-0"><em class="icon ni ni-globe"></em><span>rabbani.id</span></a>
                  </li>
                  <li>
                      <a :href="'https://www.instagram.com/rabbaniprofesorkerudung/'" target="_blank" class="btn text-white ls-05 pb-0"><em class="icon ni ni-instagram"></em><span>rabbaniprofesorkerudung</span></a>
                  </li>
                  <li>
                      <a :href="'https://www.tiktok.com/@rabbani.official'" target="_blank" class="btn text-white ls-05 pb-0"><img class="h-20p" src="../assets/images/app/ico_tiktok.png"><span class="ml-1">rabbani.official</span></a>
                  </li>
                  <li>
                      <a :href="'https://www.youtube.com/channel/UCJCMfmfO0o5aD73zCLxuR7w'" target="_blank" class="btn text-white ls-05 pb-0"><em class="icon ni ni-youtube"></em><span>rabbaniTV</span></a>
                  </li>
              </ul>
            </div>
        </div>
    </section>
</template>

<script>
export default {}
</script>