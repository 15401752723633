<template>
  <div class="nk-main w-content shadow">
    <div class="nk-wrap nk-wrap-nosidebar">
        <Header v-if="isRouteName.meta.withHeader" :isMenu="isRouteName.name"></Header>
        <div class="nk-content" :class="(!isRouteName.meta.withHeader) ? 'nk-content-single' : ''">
            <div class="container-fluid p-0">
              <div class="nk-content-inner">
                  <div class="nk-content-body"><router-view  ></router-view></div>
              </div>
            </div>
        </div>
        <Footer v-if="isRouteName.meta.withFooter"></Footer>
    </div>
  </div>
</template>

<script>
import Header from './Header';
import Footer from './Footer';

export default {
  computed: {
      isRouteName(){
          return this.$route;
      },
  },
  watch:{},
  methods:{
  },
  components :{Header, Footer},
  name: "home",
  mounted() {
    document.title = "Rabbani Jamaah - Jaringan Muamalat Rabbani";
  },
};  
</script>