<template>
    <div class="nk-header nk-header-fluid nk-header-fixed is-light attribute-fixed">
        <div class="container-fluid">
            <div class="nk-header-wrap">
                <div class="nk-header-brand">
                    <router-link :to="{name:'home'}" class="logo-link">
                        <img class="logo-dark logo-img logo-img-sm" src="../assets/images/app/ico_header_md.png" alt="logo">
                    </router-link>
                </div>
                <div class="nk-header-tools">
                    <ul class="nk-quick-nav">
                        <li><router-link :to="{name: 'home'}" href="#" class="fs-13" :class="[isMenu=='home' ? 'color-app' : 'color-app-dim']">Home</router-link></li>
                        <li ><router-link :to="{name: 'product'}" href="#" class="fs-13" :class="[isMenu=='product' ? 'color-app' : 'color-app-dim']">Produk</router-link></li>
                        <li v-if="isUser">
                            
                            <div class="dropdown">
                              <a href="#" class="btn bg-white border-0 justify-content-between no-shadow color-app btn-sm" data-toggle="dropdown" aria-expanded="false">
                                <span class="fw-normal short-text mw-100p">{{ (isUser && isUser.first_name) ? isUser.first_name : 'Akun Saya' }}</span><em class="icon ni ni-chevron-down"></em>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right dropdown-menu-auto mt-1">
                                  <ul class="link-list-opt no-bdr">
                                      <li><router-link :to="{name: 'profile'}" class="fs-12 p-1 pl-2 pr-2" href="#">Profil</router-link></li>
                                      <li ><router-link :to="{name: 'cart'}" class="fs-12 p-1 pl-2 pr-2" href="#">Keranjang</router-link></li>
                                      <li ><router-link :to="{name: 'transaction'}" class="fs-12 p-1 pl-2 pr-2" href="#">Pembelian</router-link></li>
                                      <li><a @click.prevent="processLogout" class="fs-12 p-1 pl-2 pr-2" href="#">Logout</a></li>
                                  </ul>
                              </div>
                          </div>
                        </li>
                        <li v-else><router-link :to="{name: 'login'}" href="#" class="fs-13" :class="[(isMenu=='login' || isMenu=='activation') ? 'color-app' : 'color-app-dim']">Login/Aktivasi</router-link></li>
                        <li v-if="isUser">
                            <router-link v-if="stateTotalQty > 0" :to="{name: 'cart'}" class="fs-14 p-1 pl-2 pr-2" href="#">
                                <em class="basket icon ni ni-cart pointer"></em>
                                <span class="fa-stack has-badge" :data-count="stateTotalQty">
                                </span>
                            </router-link>
                            <router-link v-else :to="{name: 'cart'}" class="fs-14 p-1 pl-2 pr-2" href="#">
                                <em class="basket icon ni ni-cart pointer"></em>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { confirmAlert, loadingAlert } from '../helpers/global'

export default {
    setup(){
        const hideElement = true;
        return {hideElement}
    },
    computed: {
       ...mapGetters({isUser:'stateUser', stateTotalQty:'stateTotalQty'}),
    },
    methods: {
        ...mapActions(['logOut']),
        async processLogout() {
            confirmAlert({type:'question',title:'Logout',text:'Yakin ingin keluar akun?',confirmLabel:'Iya, Keluar'}).then((result) => {
              if (result.isConfirmed) {
                loadingAlert({text:'Proses permintaan.....', timer:1000});
                this.logOut();
                setTimeout(() => { location.reload(true) }, 1000);
              }
            })
        },
    },
    props:{
        isMenu: { type: String, default: 'home'},
    }
}
</script>