import { createApp } from 'vue'
import Swal from 'sweetalert2'
import vSelect from 'vue-select'

//Style
import './styles/global.css'
import './styles/app.css'
import "./styles/responsive.css";
import 'vue-select/dist/vue-select.css';

//Route
import Index from './layout/Index.vue'
import store from "./config/store";
import router from './config/router'
const app = createApp(Index)
app.use(store)
app.use(router)
app.component('v-select', vSelect)
app.mount('#app')